.contact-section {
  margin-top: 50px;
  text-align: center;
}

.contact-content {
  max-width: 600px;
  margin: 0 auto;
}

.contact-content h2 {
  color: #000;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

.contact-content p {
  color: #000;
  font-size: 20px;
  line-height: 1.5;
}

.contact-list {
  list-style-type: none;
  padding: 0;
}

.contact-list li {
  margin-bottom: 10px;
}

.contact-list li a {
  text-decoration: none;
  color: #007bff;
  font-size: 20px;
  transition: color 0.3s ease;
}

.contact-list li a:hover {
  color: #0056b3;
  text-decoration: underline;
}
