@import url('https://fonts.googleapis.com/css?family=Montserrat');

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  min-width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.CMD-Style {
  font-family: 'Courier New', Courier, monospace;
  color: #0a0;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: .15em;
  animation:
    typing 3.5s steps(40, end);
}

@keyframes typing {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}

@keyframes blink-caret {

  from,
  to {
    border-color: transparent
  }

  50% {
    border-color: orange;
  }
}

.App-footer {
  margin-top: 50px;
  text-align: center;
  border-top: 1px solid #ccc;
  padding: 20px 0;
  color: #666;
  font-size: 18px;
}

.icon {
  width: 5%;
}