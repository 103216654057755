.aboutme {
  margin: 40px;
}

.timeline {
  background: var(--primary-color);
  margin: 20px auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  overflow-x: auto;
}

.outer {
  display: flex;
  flex-direction: column;
  border-top: 2px solid #333;
  position: relative;
  padding: 20px 0;
}

.card {
  position: relative;
  margin: 10px auto;
  padding: 10px;
  background: #282c34;
  color: gray;
  border-radius: 8px;
  min-width: 200px;
  max-width: 200px;
  flex: 0 0 auto;
  text-align: center;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  font-family: 'Courier New', Courier, monospace;
  color: #0a0;
  position: relative;
}

.title::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 999px;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #03BFCB;
}

.aboutme h2 {
  color: #000;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .outer {
    flex-direction: row;
  }
  
  .card {
    margin: 0 20px;
  }
}
